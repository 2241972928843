import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import SelectCards from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/SelectCards';
import NavButtons from 'components/Outdoor_Cameras/IN-9408_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9408 WQHD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9408 WQHD Quick Installation",
  "image": "./P_SearchThumb_IN-9008HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9408WQHD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9408 WQHD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9408 WQHD Quick Installation' image='/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9408_WQHD/Schnell_Installation/' locationFR='/fr/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "i-a-open-the-camera-body",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#i-a-open-the-camera-body",
        "aria-label": "i a open the camera body permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I-a Open the Camera Body`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f239632228f75ba748f717c87ec5fc48/99072/IN-9008-Quick-Installation-I-A.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCklEQVQoz52S3YqEMAyFff/X8hn0ShCUEUQdsWqSJv1ZbGGmU9Zld7+LXrQ5TXKSwic456Zpmud5WZa+76uqqut6mqZ1XefAOI7M/Iov/CdEpAMAoJTank9NxMxaayJCRGvtrThHxDt391i8Ck6JV9fJ4m0utgHnXGGtNZ8w83me+74joj5Pa0yMFpHjOM7Atm0AUIiISwpzzhERAHjvAWB4PK4vAoiolAKA6AszX+KsJCISEa110zRd16UO5T1nmaPYe78sS1mWdV0j4q3YGJMmj+LYYdu2wzBkpeVuiwgARIciWuuoyb7+flSxSQ5Q2AoRMcHnH5bgPec4pKjxv6NIh/TekL+K/8EXrdI4YMIGJNsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f239632228f75ba748f717c87ec5fc48/e4706/IN-9008-Quick-Installation-I-A.avif 230w", "/en/static/f239632228f75ba748f717c87ec5fc48/d1af7/IN-9008-Quick-Installation-I-A.avif 460w", "/en/static/f239632228f75ba748f717c87ec5fc48/899b7/IN-9008-Quick-Installation-I-A.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f239632228f75ba748f717c87ec5fc48/a0b58/IN-9008-Quick-Installation-I-A.webp 230w", "/en/static/f239632228f75ba748f717c87ec5fc48/bc10c/IN-9008-Quick-Installation-I-A.webp 460w", "/en/static/f239632228f75ba748f717c87ec5fc48/6366e/IN-9008-Quick-Installation-I-A.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f239632228f75ba748f717c87ec5fc48/81c8e/IN-9008-Quick-Installation-I-A.png 230w", "/en/static/f239632228f75ba748f717c87ec5fc48/08a84/IN-9008-Quick-Installation-I-A.png 460w", "/en/static/f239632228f75ba748f717c87ec5fc48/99072/IN-9008-Quick-Installation-I-A.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f239632228f75ba748f717c87ec5fc48/99072/IN-9008-Quick-Installation-I-A.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Remove the 4 screws that hold the back plate in place.`}</p>
    <h2 {...{
      "id": "i-b-remove-the-mainboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#i-b-remove-the-mainboard",
        "aria-label": "i b remove the mainboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I-b Remove the Mainboard`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5af369a9caacf303272ca71ce87150d/99072/IN-9008-Quick-Installation-I-B.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVQoz6WS644DIQiF5/2ftB21gxUpAuomJdtOb5tN+v0iyPEguMwr4xHP9N49/sTSe1dVe4SIcs6ImHNurX0Uq+rT9WOMlFKtdc7JzDnnj2Ize0qJSIwxhOC2iNh7/6+ziIQQEBEAcs7n8/nV4O5sZnu9mW3bxsxEJCJEdBuhqjLz5XIhotba4tVEVEphZhFprQGAZ7Zt8zoR8V4QsdZaSqm1LrchlVIAwKuZmVtDAKq1/+7M9+LPdO7ifoWZvb0x52gyd8953fmyPxtjiMh9PKrz709yiwDgeDymK+u6ppRiCCml0+kUQljXNcZ4OBxE5I3YzHwwquqBx6oqO/bNL/MLvhL/AB7HOLMSgCPPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5af369a9caacf303272ca71ce87150d/e4706/IN-9008-Quick-Installation-I-B.avif 230w", "/en/static/a5af369a9caacf303272ca71ce87150d/d1af7/IN-9008-Quick-Installation-I-B.avif 460w", "/en/static/a5af369a9caacf303272ca71ce87150d/899b7/IN-9008-Quick-Installation-I-B.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5af369a9caacf303272ca71ce87150d/a0b58/IN-9008-Quick-Installation-I-B.webp 230w", "/en/static/a5af369a9caacf303272ca71ce87150d/bc10c/IN-9008-Quick-Installation-I-B.webp 460w", "/en/static/a5af369a9caacf303272ca71ce87150d/6366e/IN-9008-Quick-Installation-I-B.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5af369a9caacf303272ca71ce87150d/81c8e/IN-9008-Quick-Installation-I-B.png 230w", "/en/static/a5af369a9caacf303272ca71ce87150d/08a84/IN-9008-Quick-Installation-I-B.png 460w", "/en/static/a5af369a9caacf303272ca71ce87150d/99072/IN-9008-Quick-Installation-I-B.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5af369a9caacf303272ca71ce87150d/99072/IN-9008-Quick-Installation-I-B.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Remove the fixing screw and use the slide to expose your camera´s mainboard.`}</p>
    <h2 {...{
      "id": "i-c-the-antenna-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#i-c-the-antenna-cable",
        "aria-label": "i c the antenna cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I-c The Antenna Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91e923179d4f2d5e67549f03a190ffb8/99072/IN-9008-Quick-Installation-I-C.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/0lEQVQoz6WSWY6DMBBEuf/pkLhCpPBBDBi73TujYMJECkEazfvyVl3Vtpv1O6o6vng8HtNG3/dt23Zdd7vdmguxu+ecU0qwUUoBgDTP/f0+DEOM8Up8DvNqXoeNmQEAItIluPGMQ+xmu1hVRYSImDnGyMzyBSKCAjDHOE210NO56t19miYiMjMR+cwrIoiYlyXGCAC7GBFV1X3vpK4c0/fLZ+ZSSkHMOe+xj6NmVgfjODLzp7j6i0hKyd3PnUspp87ruhJRFe+xRURVzSyEcN2zbk4ppWVZfmNvjeA8z7XwKbhtFYAQQs5ZVffY7+/x7Z2PW6jRzOzvP+yNf4l/AAoMN8qDPObHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91e923179d4f2d5e67549f03a190ffb8/e4706/IN-9008-Quick-Installation-I-C.avif 230w", "/en/static/91e923179d4f2d5e67549f03a190ffb8/d1af7/IN-9008-Quick-Installation-I-C.avif 460w", "/en/static/91e923179d4f2d5e67549f03a190ffb8/899b7/IN-9008-Quick-Installation-I-C.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91e923179d4f2d5e67549f03a190ffb8/a0b58/IN-9008-Quick-Installation-I-C.webp 230w", "/en/static/91e923179d4f2d5e67549f03a190ffb8/bc10c/IN-9008-Quick-Installation-I-C.webp 460w", "/en/static/91e923179d4f2d5e67549f03a190ffb8/6366e/IN-9008-Quick-Installation-I-C.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91e923179d4f2d5e67549f03a190ffb8/81c8e/IN-9008-Quick-Installation-I-C.png 230w", "/en/static/91e923179d4f2d5e67549f03a190ffb8/08a84/IN-9008-Quick-Installation-I-C.png 460w", "/en/static/91e923179d4f2d5e67549f03a190ffb8/99072/IN-9008-Quick-Installation-I-C.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91e923179d4f2d5e67549f03a190ffb8/99072/IN-9008-Quick-Installation-I-C.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You can optionally unplug the antenna cable to fully remove the mainboard slide from the camera casing.`}</p>
    <h2 {...{
      "id": "i-d-choose-your-sealing-ring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#i-d-choose-your-sealing-ring",
        "aria-label": "i d choose your sealing ring permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I-d Choose your Sealing Ring`}</h2>
    <p>{`You have the choice between 3 different sealing rings for different purpose of use:`}</p>
    <ul>
      <li parentName="ul">{`1-hole Sealing Ring: to only connect the Power Cable while using a WiFi connection`}</li>
      <li parentName="ul">{`2-hole Sealing Ring: to connect the Power Cable and LAN Cable`}</li>
      <li parentName="ul">{`3-hole Sealing Ring: to connect the Power Cable, LAN Cable and Audio Out`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards mdxType="SelectCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      